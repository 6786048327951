import React from 'react'
import { graphql } from 'gatsby'

import { PageLink, PageLinkExternal } from '../styled/PageLink'
import { BylineAuthor } from '../generic/BylineAuthor'
import { BylineDate } from '../generic/BylineDate'
import { ItemEntry } from './ItemEntry'

export function TweetItem({ item: tweet, replies, isSingle }) {
  const title = `Tweet by ${tweet.author.name}`

  const author = (
    <BylineAuthor
      author={tweet.author}
      nameHidden
      nameLink={false}
      website={`https://twitter.com/${tweet.author.username}`}
      websiteText={`@${tweet.author.username}`}
      hidden={!isSingle}
    />
  )

  const byline = (
    <>
      {isSingle && author}
      <BylineDate date={tweet.createdAt} customFormat="MMMM dd, yyyy H:mm" />
      {!isSingle && author}
    </>
  )

  return (
    <ItemEntry
      isSingle={isSingle}
      title={title}
      slug={'/tweets/' + tweet._id}
      byline={byline}
      excerpt={tweet.content}
      beforeContent={
        isSingle &&
        tweet.replyTo &&
        (tweet.replyTo.author.username === 'datapatrick' ? (
          <PageLink to={'/tweets/' + tweet.replyTo._id}>
            As reply to {tweet.replyTo.author.name}
          </PageLink>
        ) : (
          <PageLinkExternal
            href={`https://twitter.com/${tweet.replyTo.author.username}/status/${tweet.replyTo._id}`}
          >
            As reply to {tweet.replyTo.author.name}
          </PageLinkExternal>
        ))
      }
      content={tweet.content}
      extraLink={
        <>
          <PageLinkExternal
            href={`https://twitter.com/${tweet.author.username}/status/${tweet._id}`}
          >
            Show on Twitter
          </PageLinkExternal>
          {isSingle &&
            replies &&
            replies.map(reply => (
              <PageLink to={'/tweets/' + reply.tweet._id} key={reply.tweet._id}>
                Read reply by {reply.tweet.author.name}
              </PageLink>
            ))}
        </>
      }
    />
  )
}

export const query = graphql`
  fragment TweetItem on SanityTweet {
    ...TweetItemBasic
    content: _rawContent(resolveReferences: { maxDepth: 4 })
    replyTo {
      ...TweetItemBasic
    }
  }
  fragment TweetItemBasic on SanityTweet {
    _id
    createdAt
    author {
      name
      username
    }
  }
`
