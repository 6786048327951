import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout/Layout'
import { SEO } from '../components/seo'
import { smartypants } from '../utils/smartypants'
import { portableToPlain } from '../utils/portableToPlain'
import { TweetItem } from '../components/page/TweetItem'
import { PreviousNext } from '../components/generic/PreviousNext'

function TweetPageTemplate({
  data: {
    tweet,
    replies: { edges: replies },
    previousTweet,
    nextTweet,
  },
}) {
  const title = `Tweet by ${tweet.author.name} (@${tweet.author.username})`

  return (
    <Layout>
      <SEO
        title={title}
        description={smartypants(portableToPlain(tweet.content))}
      />
      <TweetItem item={tweet} replies={replies} isSingle />

      <PreviousNext
        previous={
          previousTweet && {
            title: 'Previous tweet',
            link: previousTweet._id,
          }
        }
        next={
          nextTweet && {
            title: 'Next tweet',
            link: nextTweet._id,
          }
        }
        linkPrefix="/tweets/"
      />
    </Layout>
  )
}

export default TweetPageTemplate

export const pageQuery = graphql`
  query TweetPageTemplateQuery(
    $id: String!
    $previousId: String
    $nextId: String
  ) {
    tweet: sanityTweet(_id: { eq: $id }) {
      ...TweetItem
    }
    replies: allSanityTweet(
      sort: { order: DESC, fields: createdAt }
      limit: 10
      filter: { replyTo: { _id: { eq: $id } } }
    ) {
      edges {
        tweet: node {
          ...TweetItemBasic
        }
      }
    }
    previousTweet: sanityTweet(_id: { eq: $previousId }) {
      _id
    }
    nextTweet: sanityTweet(_id: { eq: $nextId }) {
      _id
    }
  }
`
