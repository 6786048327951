import React from 'react'
import styled from 'styled-components'
import { PageLink } from '../styled/PageLink'

const StyledPreviousNext = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

export function PreviousNext({ previous, next, linkPrefix }) {
  return (
    <StyledPreviousNext>
      <li>
        {previous && (
          <PageLink to={linkPrefix + previous.link} rel="prev">
            ← {previous.title}
          </PageLink>
        )}
      </li>
      <li>
        {next && (
          <PageLink to={linkPrefix + next.link} rel="next">
            {next.title} →
          </PageLink>
        )}
      </li>
    </StyledPreviousNext>
  )
}

export function PreviousNextPagination({ currentPage, numPages, linkPrefix }) {
  const previous = currentPage - 1
  const next = currentPage + 1

  return (
    <PreviousNext
      previous={
        previous > 0 && {
          title: `Page ${previous}`,
          link: previous > 1 ? previous : '',
        }
      }
      next={
        next <= numPages && {
          title: `Page ${next}`,
          link: next,
        }
      }
      linkPrefix={linkPrefix}
    />
  )
}
